<template>

  <v-row class="fill-height">
    <v-col>
      <v-card class="fill-height">
        <v-card-title>
          <v-icon
            class="mr-4"
            style="cursor: pointer"
            title="voltar"
            @click="pushRouteToView('benefits')"
          >
            mdi-arrow-left
          </v-icon>
          <v-icon>mdi-handshake</v-icon>
          Benefício
          <v-spacer></v-spacer>
          <!--          <v-btn-->
          <!--            color="warning"-->
          <!--            :to="{ name: 'benefitEdit', params: { id: 1 } }"-->
          <!--          >-->
          <!--            <v-icon left>mdi-pencil</v-icon>-->
          <!--            Editar-->
          <!--          </v-btn>-->
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-data-visualization">
          <v-row>            
              <v-col md="6" sm="6">
              <b><v-icon>mdi-card-account-details-outline</v-icon> CNPJ:</b> {{ benefit.cnpj }}
            </v-col>
              <v-col md="6" sm="6">
              <b><v-icon>mdi-office-building</v-icon> Empresa:</b> {{ benefit.companyName }}
            </v-col>            
          </v-row>
          <v-row>
            <v-col md="12" sm="12">
              <b><v-icon>mdi-format-align-justify</v-icon> Descrição:</b> {{ benefit.desc }}
            </v-col>
          </v-row>
          <v-row>
             <v-col md="4" sm="4"><v-icon>mdi-list-status</v-icon> <b>Status:</b> {{ benefit.status }}</v-col>
            <v-col md="4" sm="4"><b><v-icon>mdi-currency-usd</v-icon> Valor:</b> {{ benefit.value | money }}</v-col>
            <v-col md="4" sm="4">
              <b><v-icon>mdi-calendar</v-icon> Data:</b>
              {{ benefit.createdAt | formatDate2 }}
            </v-col>
          </v-row>
          <v-form
            ref="boletoForm"
            v-model="formBoletoIsValid"
            v-on:submit.prevent="sendBoleto()"
          >
            <v-row v-show="benefit.statusID == 7">
              <v-col>
                <v-file-input
                  v-model="pdf"
                  accept="application/pdf"
                  placeholder="Escolha um arquivo."
                  prepend-inner-icon="mdi-file-pdf-box"
                  prepend-icon=""
                  label="Arquivo PDF"
                  outlined
                  :rules="pdfRules"
                ></v-file-input>
              </v-col>
              <v-col>
                <v-btn
                  class="btn-secondary"
                  type="submit"
                >
                  <v-icon left>mdi-send</v-icon>
                  Enviar Boleto
                </v-btn>
              </v-col>
              <v-col>
              </v-col>

            </v-row>
          </v-form>
          <v-row v-show="benefit.statusID == 2 || benefit.statusID == 6">
            <v-col>
              <v-btn
                class="btn-save"
                @click="confirmPayment()"
              >
                <v-icon left>mdi-cash-check</v-icon>
                Confirmar Pagamento
              </v-btn>
            </v-col>

          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

</template>

<script>

import {mapGetters} from "vuex";
import store from "@/store";
import {BENEFIT_GET} from "@/store/actions/benefit.type";
import {BENEFIT_RESET_STATE} from "@/store/mutations/benefit.type";
import {CHANGE_PROGRESS} from "@/store/mutations/mutations.type";
import {BenefitService} from "@/common/api/benefit.service";

export default {
  name: "benefit",
  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.commit(BENEFIT_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      store.commit(BENEFIT_RESET_STATE),
      store.dispatch(BENEFIT_GET, to.params.id)
    ]).then(() => {
      next();
    });
  },
  data: () => ({
    selected: [],
    formBoletoIsValid: false,
    pdf: null,
    pdfRules: [
      value => !value || value.size < 10000000 || 'O tamanho máximo da imagem é 10MB',
      value => {
        if (typeof value !== "undefined" && value !== null) {
          if (value.type !== "application/pdf") {
            return 'Envie um arquivo PDF'
          }
        }
        return true
      }
    ],
  }),


  methods: {
    // updateBenefitUsers(id) {
    //   console.log(id);
    //   store.dispatch(BENEFIT_EDIT_USERS, id);
    // }
    pushRouteToView(route) {
      this.$router.push({name: route});
    },
    sendBoleto() {
      if (this.$refs.boletoForm.validate()) {

        store.commit(CHANGE_PROGRESS, true);
        BenefitService.sendTicket(this.benefit.id, this.pdf)
          .then((resp) => {
            store.commit(CHANGE_PROGRESS, false);

            this.snackbar.snackbar = true;
            this.snackbar.color = "success";
            this.snackbar.text = "Salvo com sucesso";

            this.benefit.statusID = resp.data.id;
            this.benefit.status = resp.data.status;
          })
          .catch(({response}) => {
            store.commit(CHANGE_PROGRESS, false);

            this.snackbar.snackbar = true;
            this.snackbar.color = "error";
            this.snackbar.text = "Erro ao salvar.";
            // console.log(response.data);
            if (typeof response.data.message !== "undefined") {
              this.snackbar.text =
                "Erro ao salvar : " + response.data.message;
            }
          });

      } else {
        this.snackbar.snackbar = true;
        this.snackbar.color = "error";
        this.snackbar.text = "Preencha o formulário corretamente";
      }

    },
    confirmPayment() {
        store.commit(CHANGE_PROGRESS, true);
        BenefitService.confirmPayment(this.benefit.id)
          .then((resp) => {
            store.commit(CHANGE_PROGRESS, false);

            this.snackbar.snackbar = true;
            this.snackbar.color = "success";
            this.snackbar.text = "Salvo com sucesso";

            this.benefit.statusID = resp.data.id;
            this.benefit.status = resp.data.status;
          })
          .catch(({response}) => {
            store.commit(CHANGE_PROGRESS, false);

            this.snackbar.snackbar = true;
            this.snackbar.color = "error";
            this.snackbar.text = "Erro ao salvar .";
            // console.log(response.data);
            if (typeof response.data.message !== "undefined") {
              this.snackbar.text =
                "Erro ao salvar : " + response.data.message;
            }
          });
    },
  },

  computed: {
    ...mapGetters(["benefit", "checkUsers", "snackbar"])
  }
};
</script>
